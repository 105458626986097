import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-343624dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "channel--list"
}
const _hoisted_2 = { class: "channel--list grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_channel = _resolveComponent("common-channel")!

  return (_ctx.channels.length)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.channels, (channel) => {
            return (_openBlock(), _createBlock(_component_common_channel, {
              key: channel._id,
              channel: channel,
              balance: _ctx.currentBalance
            }, null, 8, ["channel", "balance"]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}