
import { defineComponent, PropType } from "vue-demi";
import { Channel } from "@/interfaces/channel/channel.dto";
import { User } from "@/interfaces/user/user.dto";
import CommonChannel from './Channel.vue';

export default defineComponent({
  name: "ChannelList",
  props: {
    channels: {
      type: Array as PropType<Array<Channel.Dto>>,
      required: true
    },
    user: {
      type: Object as PropType<User.Dto>,
      required: true
    }
  },
  computed: {
    currentBalance() {
      return Number(this.user.balance);
    },
  },
  components: {
    CommonChannel,
  }
});
