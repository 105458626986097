
import { defineComponent } from "vue-demi";
import { Channel } from "@/interfaces/channel/channel.dto";
import CommonChannel from "@/components/channel/list/index.vue";
import { useAPI } from "@/use";

export default defineComponent({
  name: "ChannelListView",
  data() {
    return {
      channels: [] as Array<Channel.Dto>,
      params: {
        limit: 15,
        page: 1,
        search: ''
      }
    }
  },
  created() {
    this.getListMethod();
    if (this.$route.query.payment && this.$route.query.payment === 'yoomoney')
      this.$router.push('/channel');
  },
  methods: {
    async getListMethod() {
      const result = await useAPI().channel.getListMethod(this.params);
      if (!result.data.length) return this.$router.push('/channel/create');
      this.channels = result.data;
    }
  },
  components: {
    CommonChannel,
  },
});
